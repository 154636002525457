export enum CommandType {
  PlaySound = 'PlaySound',
  PauseSound = 'PauseSound',
  StopSound = 'StopSound',
  PauseAllSound = 'PauseAllSound',
  PlayVideo = 'PlayVideo',
  PauseVideo = 'PauseVideo',
  HideVideo = 'HideVideo'
}

export class Command {
  type: CommandType
  value: string
  completed = false

  public constructor (type: CommandType, value: string) {
    this.type = type
    this.value = value
  }

  toJSON (): any {
    return {
      value: this.value,
      type: this.type,
      completed: this.completed
    }
  }
}
